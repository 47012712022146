import "styles/pages/page-south-monuments.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import Breadcrumbs from "../components/Breadcrumbs";

const SouthMonumentsPage = ({ pageContext }) => {
	const pageSMonuments = pageContext.pageContent;
	return (
		<Layout headerColor="#FFCE2E">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={
					require("assets/images/southmonuments-bg-main.jpg").default
				}
			/>
			<Breadcrumbs
				prevLocationLink="/zabytki"
				prevLocationLabel="Zabytki"
				currentLocationLabel="Zabytki Południe"
			/>
			<section className="phuket">
				<div className="container">
					<h2 className="section-title">
						{pageSMonuments.section1Title}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section1Subtitle}
								desc={pageSMonuments.section1Text}
								arrive={pageSMonuments.section1Transport}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageSMonuments.section1Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="wyspa-racza">
				<div className="container">
					<h2 className="section-title">
						{pageSMonuments.section3Head}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section3Title}
								desc={pageSMonuments.section3Text}
								arrive={pageSMonuments.section3Transport}
							/>
						</div>

						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageSMonuments.section3Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="phra-thong">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageSMonuments.section4Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section4Title}
								desc={pageSMonuments.section4Text}
								hours={pageSMonuments.section4Hours}
								localization={pageSMonuments.section4Address}
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="phra-nang">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section5Title}
								desc={pageSMonuments.section5Text}
								addres={pageSMonuments.section5Address}
								phone={pageSMonuments.section5Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageSMonuments.section5Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="chalong-temple">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageSMonuments.section6Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section6Title}
								desc={pageSMonuments.section6Text}
								addres={pageSMonuments.section6Address}
								hours={pageSMonuments.section6Hours}
								phone={pageSMonuments.section6Phone}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="ko-samui">
				<div className="container">
					<h2 className="section-title">
						{pageSMonuments.section7Title}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section7Subtitle}
								desc={pageSMonuments.section7Text}
								addres={pageSMonuments.section7Address}
								hours={pageSMonuments.section7Hours}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageSMonuments.section7Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="chaweng">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageSMonuments.section8Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section8Title}
								desc={pageSMonuments.section8Text}
								addres={pageSMonuments.section8Address}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="hua-thanon">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section9Title}
								desc={pageSMonuments.section9Text}
								addres={pageSMonuments.section9Address}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageSMonuments.section9Img?.sourceUrl}
								alt=""
								className="img-fluid wat-rong__img"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="hin-ta">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageSMonuments.section10Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section10Title}
								desc={pageSMonuments.section10Text}
								arrive={pageSMonuments.section10Transport}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="hin-lard">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section11Title}
								desc={pageSMonuments.section11Text}
								addres={pageSMonuments.section11Address}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={
									require("assets/images/southmonuments-img-11.png")
										.default
								}
								alt=""
								className="img-fluid "
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="krabi">
				<div className="container">
					<h2 className="section-title">
						{pageSMonuments.section14Title}
					</h2>
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-5 text-center">
							<img
								src={pageSMonuments.section14Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className="col-xl-5 offset-xl-2">
							<TextWithSymbol
								title={pageSMonuments.section14Subtitle}
								desc={pageSMonuments.section14Text}
								addres={pageSMonuments.section14Address}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="wiking-jaskinia">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 ">
							<TextWithSymbol
								title={pageSMonuments.section2Title}
								desc={pageSMonuments.section2Text}
								addres={pageSMonuments.section2Address}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageSMonuments.section2Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="thum-klang">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section15Title}
								desc={pageSMonuments.section15Text}
								addres={pageSMonuments.section15Address}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageSMonuments.section15Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="ban-ko">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageSMonuments.section16Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageSMonuments.section16Title}
								desc={pageSMonuments.section16Text}
								addres={pageSMonuments.section16Address}
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default SouthMonumentsPage;
